import React from "react"
import { Helmet } from "react-helmet"

import Topbar from './Topbar'

import './Layout.css'

const Layout = ({ children, ...rest }) => {

  return (
    <div className="App">

      <Helmet>
        <title>When today?</title>
        <meta charSet="utf-8" />
        <meta content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' name='viewport' />
        <meta name="viewport" content="width=device-width" />
      </Helmet>


      <Topbar {...rest} />

      <main className="App-main">
        {React.cloneElement(children, { ...rest })}
      </main>

    </div>
  )
}

export default Layout
