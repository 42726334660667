import React, { useEffect, useState } from "react"

const TIMER_MS = 2000

const Rotate = ({ words }) => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIndex((index + 1) % words.length)
    }, TIMER_MS)

    return () => clearTimeout(timeout)
  }, [index])

  return <span>{words[index]}</span>
}

export default Rotate
