import React from 'react'

import './Calendar.css'

import Hour from './Hour'
import Heatmap from './Heatmap'

const MODE = 'heatmap'

const getHours = (short = true) => {
  const hours = []
  const from = short ? 6 : 0
  const to = short ? 20 : 26

  for (let i = from; i <= to; i++)
    hours.push(i)

  return hours
}

const Calendar = ({ from, to, editMode, onChange, slots, availableMinutes }) => {
  const hours = getHours(false)

  return (
    <div className="CalendarWrapper">

      {MODE === 'heatmap' &&
        <Heatmap hours={hours} detailed={false} editMode={editMode} onChange={onChange} slots={slots} availableMinutes={availableMinutes} />
      }

      {MODE !== 'heatmap' && hours.map(hour => <Hour key={hour} hour={hour} />)}

    </div>
  )
}

export default Calendar
