
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import {
  collection,
  query,
  where,
  addDoc,
  onSnapshot,
} from "firebase/firestore"

import { isToday } from './utils'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

const modalStyle = {
  position: 'absolute',
  top: '10%',
  left: '5%',
  width: '90%',
  height: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
}
const innerModalStyle = {
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
}
const cardStyle = {
  background: '#ffffff',
  width: '80vw',
  marginBottom: '15px',
}
const contentCardStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
}

const Dashboard = ({ user, db }) => {
  const [days, setDays] = useState([])
  const [activities, setActivities] = useState([])
  const [open, setOpen] = useState(false)

  const [name, setName] = useState(null)
  const [location, setLocation] = useState(null)
  const [isPublic, setIsPublic] = useState(true)

  useEffect(async () => {
    const daysRef = collection(db, 'days')
    const q = query(daysRef, where('user_uid', '==', user.uid))

    const unsubscribe = onSnapshot(q, querySnapshot => {
      const days = []

      querySnapshot.forEach(doc => {
        days.push({ id: doc.id, ...doc.data() })
      })

      setDays(days)
      setActivities(days.filter(day => isToday(day.date.toDate())))
    }, error => console.log('error', error))

    return () => unsubscribe()
  }, [])

  const onCreate = async () => {
    const docRef = await addDoc(collection(db, 'days'), {
      name,
      location,
      visibility: isPublic ? 'public' : 'private',
      user_uid: user.uid,
      date: new Date(),
    })

    setOpen(false)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={innerModalStyle}>
            <Box mb={4}>
              <h1>Create a new whentoday</h1>
            </Box>

            <Box mb={2}>
              <TextField
                required
                label="Activity name"
                variant="outlined"
                onChange={e => setName(e.currentTarget.value)}
              />
            </Box>
            <Box mb={2}>
            <TextField
              label="Activity location"
              variant="outlined"
              onChange={e => setLocation(e.currentTarget.value)}
            />
            </Box>
            <FormGroup>
              <FormControlLabel control={<Switch checked={isPublic} inputProps={{ 'aria-label': 'controlled' }} onChange={e => setIsPublic(e.target.checked)} />} label="Public" />
            </FormGroup>
            <Box mt={4}>
              <Button variant="contained" onClick={onCreate}>Create</Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Box mb={2}>Today activities</Box>
        {activities.map(day => {
          return <Card variant="outlined" key={day.id} style={cardStyle}>
            <CardContent style={contentCardStyle}>
              <Typography sx={{ textAlign: 'left', fontSize: 14 }} color="text.secondary" gutterBottom>
                {day.visibility}
              </Typography>
              <Typography variant="h5" component="div" sx={{ textAlign: 'left' }}>
                {day.name}
              </Typography>
              <Typography sx={{ textAlign: 'left' }} color="text.secondary">
                {day.location}
              </Typography>
            </CardContent>
            <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box>
                <Button size="small"><Link underline="none" to={`/d/${day.id}`} style={{ textDecoration: 'none' }}>View</Link></Button>
              </Box>
            </CardActions>
          </Card>
        })}
      <Box>
        <Button onClick={() => setOpen(true)}>{activities.length === 0 ? 'Create your first activity!' : 'Add another activity'}</Button>
      </Box>
    </>
  )
}

export default Dashboard
