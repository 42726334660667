import React, { useState, useEffect, useRef } from 'react'

import Chip from '@mui/material/Chip'

const getAvailableMinutes = detailed => detailed ? [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55] : [0, 15, 30, 45]

const temp = (value, max) => {

  if (!value) return 0

  const ratio = value / max

  if (ratio > .8) return 5
  if (ratio > .6) return 4
  if (ratio > .4) return 3
  if (ratio > .2) return 2

  return 1
}

const getMinutes = ({ hour, detailed, legend = false, from, to, onClick, availableMinutes }) => {
  const blocks = []
  const minutes = getAvailableMinutes(detailed)
  const { availability , max } = (availableMinutes || { availability: {}, max: 0 })

  for (let i = 0; i < minutes.length; i++) {
    const value = hour + minutes[i] / 100
    const selected = value === from || (from && to && value >= from && value <= to)
    const available = availability[`${hour}:${minutes[i]}`]

    blocks.push(
      <div
        key={minutes[i]}
        onClick={e => onClick(value)}
        className={`Minute Minute-${temp(available, max)} ${selected ? 'selected' : ''} ${legend ? 'Legend' :  ''} Minute-${hour}-${minutes[i]}`}>
          {legend ? minutes[i] : ''}
          {!legend && available ? available : null}
      </div>
    )
  }

  return blocks
}

const Heatmap = ({ hours, detailed = false, editMode, onChange, slots, availableMinutes }) => {
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [isEditable, setIsEditable] = useState(editMode)

  const startRef = useRef(null)
  const parentRef = useRef(null)

  const scroll = () => {
    if (!startRef || !parentRef) return

    if (!window._scrollTop) {
      const childRect = startRef.current.getBoundingClientRect()
      const parentRect = parentRef.current.getBoundingClientRect()
      window._scrollTop = (childRect.top - parentRect.top) - 28
    }

    parentRef.current.scrollTop = window._scrollTop
  }

  useEffect(scroll, [startRef, parentRef])

  useEffect(() => {
    onChange && onChange({ from, to })
  }, [from, to])

  const onClick = value => {
    // no nothing when not in edit mode
    if (!isEditable) return

    // if we start selecting to instead of from
    if (from && !to && value < from) {
      setTo(from)
      setFrom(value)
      return
    }

    // if no from yet store it
    if (!from || value < from) return setFrom(value)

    if (value > to) return setTo(value)

    if (value > from && value < to)
      return (value < ((to + from)/2)) ? setFrom(value) : setTo(value)

    // set to
    setTo(value)
  }

  const handleClick = () => {
    setFrom(null)
    setTo(null)
    scroll()
  }

  return (
    <div>
      <div className={`Heatmap ${isEditable ? 'editing': ''}`} ref={parentRef}>
        <div className="LegendLine">
            <strong>&nbsp;</strong>
            {getMinutes({ detailed, legend: true, onClick: () => {} })}
        </div>

        <div>
          {hours.map(hour => {
              return <div className="Line" key={hour}>
                {hour === 6 && <div ref={startRef} />}
                <strong>{hour % 24}</strong>
                {getMinutes({ hour, detailed, from, to, onClick, availableMinutes })}
              </div>
          })}
        </div>
      </div>

      {from &&
        <div className="ClearSelection">
          <Chip
            label="Clear selection"
            color="primary"
            size="small"
            onClick={handleClick}
            onDelete={handleClick}
          />
        </div>
      }

      {!isEditable &&
        <div className="ClearSelection">
          <Chip
            label="Add your timeslot"
            color="primary"
            size="small"
            onClick={() => setIsEditable(true)}
          />
        </div>
      }

      {isEditable && !from &&
        <div className="ClearSelection">
        <Chip
          label="Stop editing"
          color="primary"
          size="small"
          onClick={() => setIsEditable(false)}
        />
      </div>
      }
    </div>
  )
}

export default Heatmap
