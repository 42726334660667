import React, { useState, useEffect } from "react"
import { app, auth, db, onAuthStateChanged } from './firebaseClient'
import { updateProfile } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom"

import { useSelector, useDispatch } from 'react-redux'
import { setUser } from './store/authSlice'

import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress';

import Layout from './Layout'

const style = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#282c34',
  color: '#ffffff',
}

const Firewall = ({ component, mustBeLogged = false, redirectToHome = true }) => {
  const [loading, setLoading] = useState(true)
  const user = useSelector(state => state.auth.user)
  const username = useSelector(state => state.auth.username)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (user && user.displayName === null && username)
      updateProfile(user, { displayName: username })
        .then(() => console.log('updated username'))
        .catch(error => console.log('error'))
  }, [username])

  useEffect(() => {
    onAuthStateChanged(auth, user => {

      if (user)
        dispatch(setUser(user))

      setLoading(false)

      return () => {}
    })
  }, [])

  useEffect(async () => {
    if (!user) return

    const docRef = doc(db, 'users', user.uid)
    const docSnap = await getDoc(docRef)

    if (!docSnap.exists()) {
      await setDoc(docRef, {
        email: user.email,
        displayName: user.displayName,
        emailVerified: user.emailVerified,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        isAnonymous: false,
      })
    }

  }, [user])

  if (loading) return (
    <Box sx={style}>
      <Box sx={{ width: '33%' }}>
        <LinearProgress />
      </Box>
    </Box>
  )

  if (mustBeLogged && !user) {
    if (redirectToHome)
      navigate('/')

    return (
      <Layout user={user} auth={auth} app={app} db={db}>
        <Box sx={style}>Must be logged</Box>
      </Layout>
    )
  }

  const Component = component

  return (
    <Layout user={user} auth={auth} app={app} db={db}>
      <Component />
    </Layout>
  )
}

export default Firewall
