import React, { useState, useEffect } from 'react'
import { doc, collection, query, onSnapshot, addDoc } from "firebase/firestore"
import { useParams } from "react-router-dom"
import { signInAnonymously } from "firebase/auth";
import { useSelector, useDispatch } from 'react-redux'
import { setUsername } from './store/authSlice'

import Calendar from './Calendar'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'

import GroupIcon from '@mui/icons-material/Group'
import VerifiedIcon from '@mui/icons-material/Verified'
import LocationOnIcon from '@mui/icons-material/LocationOn'

const modalStyle = {
  position: 'absolute',
  top: '10%',
  left: '5%',
  width: '90%',
  height: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
}
const innerModalStyle = {
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
}

const stringify = float => {
  if (float === null) return null
  if (float % 1 === 0) return `${float % 24}:00`
  const { hours, minutes } = stringToObj((''+float).replace('.', ':').replace(':3', ':30'))
  return `${hours%24}:${minutes}`
}

const duration = (a, b) => {
  return stringify(Math.round((b - a)*100)/100)
}

const getAvailableMinutes = detailed => detailed ? [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55] : [0, 15, 30, 45]

const stringToObj = timeString => {
  const [hours, minutes] = timeString.split(':')
  return { hours: parseInt(hours), minutes: parseInt(minutes) }
}

const availableMinutesMap = (slots, detailed = false) => {
  const availability = {}
  const minutes = getAvailableMinutes(detailed)
  let hour, minute, key, max = 0

  for (let i = 0; i < slots.length; i++) {
    const to = stringToObj(slots[i].to)
    const from = stringToObj(slots[i].from)

    for (hour = from.hours; hour <= to.hours; hour++) {
      for (minute = 0; minute < minutes.length; minute++) {
        if (hour === from.hours && minutes[minute] < from.minutes) continue
        if (hour === to.hours && minutes[minute] > to.minutes) continue

        key = `${hour}:${minutes[minute]}`
        availability[key] = (availability[key] || 0) + 1

        if (availability[key] > max) max = availability[key]
      }
    }

  }

  return { availability, max }
}

const Day = ({ auth, db }) => {
  const { uid } = useParams()
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)

  const [loading, setLoading] = useState(true)
  const [day, setDay] = useState(null)
  const [version, setVersion] = useState(0)
  const [editMode, setEditMode] = useState(false)

  const [timeSlots, setTimeSlots] = useState([])
  const [mine, setMine] = useState([])
  const [others, setOthers] = useState([])

  const [availableMinutes, setAvailableMinutes] = useState({ availability: {}, max: 0 })
  const [currentTimeSlot, setCurrentTimeSlot] = useState({})

  const { from, to } = currentTimeSlot
  const [open, setOpen] = useState(false)
  const [name, setName] = useState(null)

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'days', uid), snapshot => {
      setDay(snapshot.data())
      setLoading(false)
    }, error => {
      console.log('err', error)
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (!day) return

    const q = query(collection(db, 'days', uid, 'slots'))
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const slots = []

      querySnapshot.forEach(doc => {
        slots.push({ id: doc.id, ...doc.data() })
      })

      setTimeSlots(slots.sort((a, b) => a.date.seconds - b.date.seconds))
      setAvailableMinutes(availableMinutesMap(slots))

      setMine(slots.filter(({ user_uid }) => user_uid === user?.uid ))
      setOthers(slots.filter(({ user_uid }) => user_uid !== user?.uid ))

    }, error => console.log('error', error))

    return () => unsubscribe()
  }, [day])

  useEffect(() => {
    if (open && name && user) {
      onCreate()
      setOpen(false)
      setName(null)
    }
  }, [user])

  const onSignUpAnon = async () => {
    signInAnonymously(auth)
      .then(() => {
        dispatch(setUsername(name))
      })
      .catch(({ code, message }) => {
        console.log(code, message)
      })
  }

  const onCreate = async () => {

    if (!user) {
      console.log('user not set :(')
      return setOpen(true)
    }

    const docRef = await addDoc(collection(db, 'days', uid, 'slots'), {
      from: stringify(from),
      to: stringify(to),
      user_uid: user.uid,
      username: name || user.displayName,
      date: new Date(),
    })

    setCurrentTimeSlot({})
    setVersion(version+1)
  }

  if (loading) return '...'

  if (!day) return 'No day found or permission denied'

  return (
    <>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={innerModalStyle}>
            <Box mb={4}>
              <h1>Please enter you name</h1>
              <small>Or signup if you want to edit your times and create your own whentoday</small>
            </Box>

            <Box mb={2}>
              <TextField
                required
                label="User name"
                variant="outlined"
                value={name}
                onChange={e => setName(e.currentTarget.value)}
              />
            </Box>
            <Box mt={4}>
              <Button variant="contained" onClick={onSignUpAnon}>Use that name</Button>
            </Box>
          </Box>
        </Box>
      </Modal>


      <Box sx={{ display: 'flex', width: '90vw' }}>
        <Box sx={{ display: 'flex', flex: '1 auto' }}>
          <VerifiedIcon />&nbsp;{day.name}
        </Box>
        <Box sx={{ display: 'flex', flex: 'none' }}>
          <Chip style={{ color: `white` }} label={day.visibility} size="small" variant="outlined" />
        </Box>
      </Box>

      {day.location &&
        <Box sx={{ display: 'flex', width: '90vw', marginTop: '5px' }}>
          <LocationOnIcon />&nbsp;{day.location}
        </Box>
      }

      <Box sx={{ display: 'flex', width: '90vw', marginTop: '5px' }}>
        <GroupIcon />&nbsp;{timeSlots.length}
      </Box>

      <Box sx={{ marginTop: '20px', marginLeft: '15px', display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
        <Box>
          <Calendar
            key={version}
            editMode={editMode}
            onChange={setCurrentTimeSlot}
            slots={timeSlots}
            availableMinutes={availableMinutes}
          />
        </Box>
        <Box sx={{ paddingLeft: '15px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Box mb={2}>Your timeslots:</Box>
          <Box sx={{ textAlign: 'left' }}>

            {!mine && currentTimeSlot.from && <small>You don't have selected any timeslot yet</small>}

            {currentTimeSlot.from &&
              <Box key={from+to}>
                <div>{`From ${from ? stringify(from) : '--'} to ${to ? stringify(to) : '--'}`}</div>
                {from && to && <div>Duration: {duration(from, to)}</div>}
                {from && to &&
                  <Button variant="contained" size="small" onClick={() => onCreate(from, to, user)}>Save</Button>
                }
              </Box>
            }

            {mine.map(({ from, to }) => {
              return (
                <Box key={from+to}>
                  <div>{`From ${from ? stringify(from) : '--'} to ${to ? stringify(to) : '--'}`}</div>
                </Box>
              )
            })}

            <Box mt={3}>
              Others:

              {others.length === 0 &&
                <Box mt={2}>No other user timeslot</Box>
              }

              <Box mt={2}>
                {others.map(({ from, to, username }) => {
                  return (
                    <Box key={from+to}>
                      <small>{username}</small>
                      <div>{`From ${from ? stringify(from) : '--'} to ${to ? stringify(to) : '--'}`}</div>
                    </Box>
                  )
                })}
              </Box>
            </Box>

          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Day
