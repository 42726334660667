import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom"

import Rotate from './Rotate'

const App = ({ user }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (user) navigate('/dashboard')
  }, [user])

  return (
    <>
      <h1>When today?</h1>
      <h3>Find the best moment to do something today with your <br/><Rotate words={['friends', 'familly', 'colleagues', 'followers']} />...</h3>
    </>
  )
}

export default App;
