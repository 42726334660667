import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Routes, Route } from "react-router-dom"

import { Provider } from 'react-redux'
import store from './store'

import App from './App'
import Day from './Day'
import Dashboard from './Dashboard'

import Firewall from './Firewall'

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Firewall component={App} />} />
          <Route path="/d/:uid" element={<Firewall component={Day} />} />
          <Route path="/dashboard" element={<Firewall component={Dashboard} mustBeLogged />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
