// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD_spb-aXDyzv94cp5S4zZmu6u6ZBHZJXc",
  authDomain: "whentoday-670b4.firebaseapp.com",
  projectId: "whentoday-670b4",
  storageBucket: "whentoday-670b4.appspot.com",
  messagingSenderId: "984246876241",
  appId: "1:984246876241:web:9bc3b177571454aa3de070",
  measurementId: "G-LBBMVV7XG7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();
const auth = getAuth();

export {
  app,
  auth,
  onAuthStateChanged,
  db,
  analytics,
}
