import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    username: null,
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload
      state.username = payload.displayName
    },
    setUsername: (state, { payload }) => {
      state.username = payload
    }
  },
})

export const { setUser, setUsername } = authSlice.actions

export default authSlice.reducer
