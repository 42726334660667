import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useSelector } from 'react-redux'

import { initFirebaseUi } from "./firebaseUiClient"

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const UserMenu = ({ username, profilePicture, auth }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    auth.signOut()
      .then(() => window.location.reload())
      .catch(() => console.log('error while loging out'))
  }

  return (
    <>
      <Chip
        onClick={handleClick}
        avatar={<Avatar alt={username} src={profilePicture} />}
        label={username}
        variant="outlined"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  )

}

const Topbar = ({ auth }) => {
  const [open, setOpen] = useState(false)
  const user = useSelector(state => state.auth.user)
  const username = useSelector(state => state.auth.username)

  useEffect(() => {
    if (!open || window._auth_mounted) return
    setTimeout(() => {
      initFirebaseUi(auth, '#firebaseui-auth-container')
    }, 200)
  }, [open])

  return (
    <>
      <Modal
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="firebaseui-auth-container" sx={style} />
      </Modal>

      <header className="App-header">
        <Link to={'/'}>
          <img src={process.env.PUBLIC_URL + '/logo-1@0.5x.png'} style={{ marginLeft: '15px', marginTop: '4px', width: '38px', height: '38px' }} />
        </Link>

        <div className="App-headerCenter">

        </div>

        <div className="App-login">
          {user &&
            <UserMenu username={username} auth={auth} profilePicture={user.photoURL} />
          }

          {!user &&
            <Button variant="contained" onClick={() => setOpen(true)}>login</Button>
          }
        </div>
      </header>
    </>
  )
}

export default Topbar
