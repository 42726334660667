import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

const uiConfig = {
  signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
  ],
  tosUrl: '/',
  privacyPolicyUrl: '/',
  signInFlow: 'popup',
}

export const initFirebaseUi = (auth, container, config = {}) => {
  const ui = new firebaseui.auth.AuthUI(auth)
  ui.start(container, { ...uiConfig, ...config})
  window._auth_mounted = true
}
